@import "variables.scss";
@import "colors.scss";

// Snackbar Styling
.error-snackbar {
    background: #F44337 !important;
    color: white !important;
}

.success-snackbar {
    background: #4CAF51 !important;
    color: white !important;
}

.warn-snackbar {
    background: #FF9800 !important;
    color: white !important;
}

.info-snackbar {
    background: #03A9F4 !important;
    color: white !important;
}