$hubbub-primary: #ff9e18;
$hubbub-primary-01: hsla(34, 90%, 68%, 0.1);
$hubbub-primary-02: hsla(34, 90%, 68%, 0.2);
$hubbub-secondary: #0071ce;

$orange: #ff9e18;
$yellow: rgb(255, 198, 0);
$blue: #0071ce;
$light-blue: #798DFE;
$white-blue: #B1BCFF;
$blue-white: #f4f4f4;
$pink: #ff5100;
$dark-pink: #ff0f60;
$green: #00b189;
$red: #fd2d2d;
$violet: #9013FE;
$white: white;
$dark-grey: #4A4A4A;
$light-grey: #B9B9B9;
$grey: #6E6E6E;
$sky: #c0caff;
$purple: #9000cc;


$white-35: rgba(255, 255, 255, 0.35);
$white-85: rgba(255, 255, 255, 0.85);
$white-80: #FFFFFF80;

$gray-08: rgba(110, 110, 110, 0.8);
$gray-015: rgba(110, 110, 110, 0.15);
$gray-80: #D8D8D880;
$gray-06: rgba(110, 110, 110, 0.6);

$black-08: rgba(0, 0, 0, 0.08);

$pink-15: rgba(255, 92, 147, 0.15);
$blue-15: rgba(83, 109, 254, 0.15);
$green-15: rgba(60, 212, 160, 0.15);
$yellow-15: rgba(255, 194, 96, 0.15);
$violet-15: rgba(144, 19, 254, 0.15);


$shadow-white: #DDD;
$shadow-grey: #B2B2B21A;
$shadow-dark-grey: #9A9A9A1A;

$background-color: #f4f4f4;
