@import "src/assets/scss/colors";
@import "src/assets/scss/variables-demo";
@import "src/assets/scss/font";

.mat-stroked-button {
    border-color: black !important;
    color: black !important;
    // border-color: $hubbub-primary !important;
}

.clickable-icon {
    cursor: pointer;

    &:hover {
        color: $hubbub-primary;
    }
}

.export-to-excel-button {
    border-color: green !important;
    color: green !important;

    &:hover {
        background-color: rgba(0, 128, 0, 0.116) !important;
        color: black !important;
    }
}

.mat-icon {
    overflow: inherit !important;
}