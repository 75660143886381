.k-grid {
    border: none !important;

    // If you have a form field field remove the padding
    .mat-form-field-wrapper {
        padding-bottom: 0px !important;
    }

    .k-grid-header {
        border-bottom: 1px solid;
    }

    th {
        border-left: none;
        padding: 20px;
        font-size: 14px;
        font-weight: 400;
        line-height: 24px;
        text-transform: uppercase;
    }

    tr.clickable {
        cursor: pointer;

        &:hover {
            background-color: $hubbub-primary-02;
        }
    }

    td {
        border-left: none;
        padding: 20px;
        border-right: none;
        border-right-width: 0 !important;
    }
    
    .k-grid-pager {
        border-top: none;
    }

    .k-grid-header-sticky, .k-grid .k-grid-content-sticky, .k-grid .k-grid-footer-sticky {
        border-right: none;
    }

    .k-checkbox {
        border-color: rgba(0, 0, 0, 0.5);
    }    
}