/* You can add global styles to this file, and also import other style files */
@import "variables.scss";
@import "responsiveness/mixins.scss";

html, body { 
    min-height: 100vh;
    height: auto;
    margin: 0; 

    .content-container{
        height: 100vh;
    }
}
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }


$spacer: 1rem;
.mt-0 {
    margin-top: 0 !important;
}

.mt-1 {
    margin-top: ($spacer * .25) !important;
}

.mt-2 {
    margin-top: ($spacer * .5) !important;
}

.mt-3 {
    margin-top: ($spacer * 1) !important;
}

.mt-4 {
    margin-top: ($spacer * 2) !important;
}

.mt-5 {
    margin-top: ($spacer * 4) !important;
}

.my-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
}

.my-1 {
    margin-top: ($spacer * .25) !important;
    margin-bottom: ($spacer * .25) !important;
}
.my-2 {
    margin-top: ($spacer * .5) !important;
    margin-bottom: ($spacer * .5) !important;
}
.my-3 {
    margin-top: ($spacer * 1) !important;
    margin-bottom: ($spacer * 1) !important;
}
