@import "src/assets/scss/colors";

.timepicker {
    .timepicker__header {
      background-color: $hubbub-primary !important;
    }
  
    .clock-face__clock-hand {
      background-color: $hubbub-primary !important;
  
      &::after {
        background-color: $hubbub-primary !important;
      }
  
      &::before {
        border-color: $hubbub-primary !important;
      }
    }
  
    .clock-face__number {
      > span.active {
        background-color: $hubbub-primary !important;
      }
    }
  
    button:not(.timepicker-dial__item) {
      color: $hubbub-primary !important;
    }
}